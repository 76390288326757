import { Col, Row } from "react-bootstrap";
import { experience } from "../../data";


function Timeline() {
    return (
        <div id="timeline">

            {experience.map((entry, index) => (
                <Row key={index}>
                    <Col xs={12} md={3} className="timeline__border--desktop">
                        <div className="timeline__year__wrapper">
                            <p className="timeline__year">
                                {entry.year}
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={9} className="timeline__border--mobile">
                        <div className="timeline__text__wrapper">
                            <div className="timeline__text">
                                <h3 className="timeline__text__title">{entry.title}</h3>
                                <p className="timeline__text__subtitle">{entry.subtitle}</p>
                                <p className="timeline__text__content">{entry.content}</p>

                            </div>
                        </div>
                    </Col>
                </Row>
            ))}
        </div>
    )
}

export default Timeline