import { Container, Row, Col } from "react-bootstrap";
import { wobbleData } from "../../data";
import { useEffect } from "react";
import ButtonComponent from "../ui/ButtonComponent";

export const Projects = () => {
    useEffect(() => {
        const mouseEnter = (event) => {
            if (event.target === document || !event.target) return;
            const element = event.target.closest(".wobble-card");
            resetPosition(element);

            const contentContainer = element.querySelector('.wobble-content');
            const cords = element.getBoundingClientRect();
            const cursorX = event.clientX - cords.left - element.offsetWidth / 2;
            const cursorY = event.clientY - cords.top - element.offsetHeight / 2;

            const toMoveX = cursorX / 20;
            const toMoveY = cursorY / 10;

            element.style.transition = 'transform 0.2s ease-out';
            element.style.transform = `translateX(${toMoveX}px) translateY(${toMoveY}px)`;

            contentContainer.style.transition = 'transform 0.2s ease-out';
            contentContainer.style.transform = `translateX(${-toMoveX}px) translateY(${-toMoveY}px) scale(1.03)`;
        };

        const mouseleave = (event) => {
            if (event.target === document || !event.target) return;
            const target = event.target.closest(".wobble-card") ?? null;
            resetPosition(target);
        };

        const resetPosition = target => {
            document.querySelectorAll('.wobble-card').forEach(element => {
                if (element !== target || target === null) {
                    element.style.transform = ``;
                    const contentContainer = element.querySelector('.wobble-content');
                    contentContainer.style.transform = ``;
                }
            });
        };

        document.querySelectorAll('.wobble-card').forEach(wobble => {
            wobble.addEventListener('mousemove', mouseEnter);
            wobble.addEventListener('mouseleave', mouseleave);
        });
        window.addEventListener('mousemove', mouseleave);

        return () => {
            document.querySelectorAll('.wobble-card').forEach(wobble => {
                wobble.removeEventListener('mousemove', mouseEnter);
                wobble.removeEventListener('mouseleave', mouseleave);
            });
            window.removeEventListener('mousemove', mouseleave);
        };
    }, []);

    return (
        <section id="projects">
            <Container>
                <Row>
                    <Col>
                        <h2>Projekte</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={8}>
                        <div className="card-1 wobble-card">
                            <div className="wobble-background" />
                            <div className="wobble-content">
                                <div className="content">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h3>{wobbleData[0].title}</h3>
                                            <p>{wobbleData[0].content}</p>

                                        </Col>
                                        <Col xs={12} md={6} className="col--relative">
                                            <div className="wobble-images">
                                                <img src={wobbleData[0].image[0].src} alt={wobbleData[0].image[0].alt} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <ButtonComponent link={wobbleData[0].links[0].src}
                                                hasBackground={true}
                                                text={wobbleData[0].links[0].text}
                                            />
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <ButtonComponent link={wobbleData[0].links[1].src}
                                                hasBackground={true}
                                                text={wobbleData[0].links[1].text}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={12} lg={4}>
                        <div className="card-5 wobble-card">
                            <div className="wobble-background" />

                            <div className="wobble-content">
                                <div className="content">
                                    <Row>
                                        <Col xs={12}>
                                            <h3>{wobbleData[4].title}</h3>
                                            <p>{wobbleData[4].content}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <ButtonComponent link={wobbleData[4].links[0].src}
                                                hasBackground={false}
                                                text={wobbleData[4].links[0].text}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={6}>
                        <div className="card-4 wobble-card">
                            <div className="wobble-background" />

                            <div className="wobble-content">
                                <div className="content">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h3>{wobbleData[3].title}</h3>
                                            <p>{wobbleData[3].content}</p>
                                        </Col>
                                        <Col xs={12} md={6} className="col--relative">
                                            <div className="wobble-images">
                                                <img src={wobbleData[3].image} alt={wobbleData[3].alt} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <ButtonComponent link={wobbleData[3].links[0].src}
                                                hasBackground={false}
                                                text={wobbleData[3].links[0].text}
                                            />
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <ButtonComponent link={wobbleData[3].links[1].src}
                                                hasBackground={true}
                                                text={wobbleData[3].links[1].text}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                        <div className="card-2 wobble-card">
                            <div className="wobble-background" />

                            <div className="wobble-content">
                                <div className="content">
                                    <Row>
                                        <Col xs={12}>
                                            <h3>{wobbleData[1].title}</h3>
                                            <p>{wobbleData[1].content}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} lg={12}>
                                            <ButtonComponent link={wobbleData[1].links[0].src}
                                                hasBackground={false}
                                                text={wobbleData[1].links[0].text}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="card-3 wobble-card">
                            <div className="wobble-background" />

                            <div className="wobble-content">
                                <div className="content">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h3>{wobbleData[2].title}</h3>
                                            <p>{wobbleData[2].content}</p>
                                        </Col>
                                        <Col xs={12} md={6} className="col--relative">
                                            <div className="wobble-images">
                                                <img src={wobbleData[2].image} alt={wobbleData[2].alt} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <ButtonComponent link={wobbleData[2].links[0].src}
                                                hasBackground={false}
                                                text={wobbleData[2].links[0].text}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </section>
    );
}
