import { Col, Row, Container } from "react-bootstrap"
import { about_quote } from "../../data";
import { about } from "../../data"
import photo from '../../assets/img/about/photo.webp';

export const About = () => {
    return (
        <section id="about">
            <Container>
                <Row >
                    <Col lg={6} md={12}>
                        <Row>
                            <Col>
                                <h2>Über mich</h2>
                                <Col xs={2} lg={1}>
                                    <span className="design-line--horizontal"></span>
                                </Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <blockquote><i>{about_quote[0].quote}</i><br />{about_quote[0].author}</blockquote>
                                <p>{about}</p>
                            </Col>

                        </Row>
                    </Col>
                    <Col lg={6} md={12}>
                        <div className="photo__wrapper">
                            <div className="photo__background__wrapper">
                                <div className="photo__background" />
                            </div>
                            <img className="photo" src={photo} alt="Foto von mir" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}