import { Col, Row, Container } from "react-bootstrap"
import SkillsCard from "../ui/SkillsCard"

export const Skills = () => {
    return (
        <section id="skills">
            <Container>
                <Row>
                    <Col>
                        <h3>Skills & Tools: Mein persönliches Periodensystem</h3>
                    </Col>
                </Row>
                <SkillsCard />
                <div className="shape__container">
                    <div className="shapeRow">
                        <div className="shape small" />
                        <div className="shape big" />
                        <div className="shape big" />
                        <div className="shape big" />
                    </div>
                    <div className="shapeRow">
                        <div className="shape small" />
                        <div className="shape big" />
                        <div className="shape small" />
                        <div className="shape small" />
                    </div>
                    <div className="shapeRow">
                        <div className="shape small" />
                        <div className="shape big" />
                        <div className="shape small" />
                        <div className="shape small" />
                    </div>
                    <div className="shapeRow">
                        <div className="shape big" />
                        <div className="shape small" />
                        <div className="shape big" />
                        <div className="shape big" />
                    </div>
                </div>
            </Container>
        </section>
    )
}