import { Col, Row, Container } from "react-bootstrap"
import Timeline from "../ui/Timeline"


export const Experience = () => {
    return (
        <section id="experience">
            <Container>
                <Row>
                    <Col>
                        <h2>Meine Erfahrung</h2>
                    </Col>
                </Row>
                <Timeline />
            </Container>
        </section>
    )
}