import { social } from "../../data"

const Social = ({
    className
}) => {
    return (
        <div className={className}>
            {social.map((element, index) => (
                <div key={index} className="social-item">
                    <a href={element.link} target="_blank" rel="noreferrer">
                        <img className="social-item--image" src={element.icon} alt={element.alt} />
                    </a>
                </div>
            ))}
        </div>
    )
}

export default Social