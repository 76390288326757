import javaScriptLogo from '../assets/img/skills/javaScript.svg';
import gitLogo from '../assets/img/skills/git.svg';
import webflowLogo from '../assets/img/skills/webflow.svg';
import sassLogo from '../assets/img/skills/sass.svg';
import reactLogo from '../assets/img/skills/react.svg';
import webpackLogo from '../assets/img/skills/webpack.svg';
import luaLogo from '../assets/img/skills/lua.svg';
import cSharpLogo from '../assets/img/skills/c_sharp.svg';

import maxenergyAT from '../assets/img/projects/www.maxenergy.at_.webp';
import maxenergyDE from '../assets/img/projects/www.maxenergy.de_.webp';
import sortgroup from '../assets/img/projects/sortgroup_.webp';
import websiteRoulette from '../assets/img/projects/collabo93.github.io_.webp';

import socialGithub from '../assets/img/contact/icons-github.svg';
import socialLinkedIn from '../assets/img/contact/icons-linkedin.svg';
import socialTelegram from '../assets/img/contact/icons-telegram.svg';
import socialEmail from '../assets/img/contact/icons-email.svg';


export const skills_alt = [
    {
        desc: 'JavaScript',
        image: javaScriptLogo,
        alt: 'JavaScript Logo'
    }, {
        desc: 'Git Source Control',
        image: gitLogo,
        alt: 'Git Logo'
    }, {
        desc: 'Webflow',
        image: webflowLogo,
        alt: 'Webflow Logo'
    }, {
        desc: 'Sass',
        image: sassLogo,
        alt: 'Sass Logo'
    }, {
        desc: 'Webpack',
        image: webpackLogo,
        alt: 'Webpack Logo'
    }, {
        desc: 'React',
        image: reactLogo,
        alt: 'React Logo'
    }, {
        desc: 'Lua',
        image: luaLogo,
        alt: 'Lua Logo'
    }, {
        desc: 'C#',
        image: cSharpLogo,
        alt: 'C-Sharp Logo'
    }
]

export const candidate = {
    name: 'Timo Blessing',
    skills: ['JavaScript', 'Git Source Control', 'Webflow', 'Sass', 'Webpack', 'React'],
    otherTechnologies: ['Lua', 'C#', 'C++', 'OpenGL'],
    personalityTraits: {
        hardWorker: true,
        quickLearner: true,
        problemSolver: true,
        teamPlayer: true,
        lovesPinapplePizza: false
    },
    education: {
        degree: 'Bachelor of Science (B.SC.)',
        university: 'Hochschule Ravensburg-Weingarten',
        graduationYear: 2021
    },
    funFact: 'Ich mache mehr `console.log()`-Ausgaben als Selfies.',
    hireable: 'Ist bereit, das Team zu rocken! 🎉',
    notHirebale: 'Braucht vielleicht noch etwas Zeit in der Debugging-Hölle. 🔧'
}

export const about_quote = [{
    quote: '"What I cannot create, I do not understand."',
    author: '— Richard Feynman'
}];
export const about = 'Diese Philosophie treibt mich als Webentwickler an. Meine Leidenschaft für die ständige Weiterbildung in neuen Technologien wie Website Crawling und OpenGL ermöglicht es mir, kreative Lösungen zu entwickeln und komplexe Probleme zu verstehen. In meinen Projekten kombiniere ich technisches Wissen mit Begeisterung für innovative Ansätze, um ansprechende und funktionale Webanwendungen zu schaffen.'
export const projects = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'

export const wobbleData = [
    {
        title: 'Website Relaunch',
        content: 'Ich konnte maßgeblich an einem umfangreichen Website-Relaunch in zwei Ländern mitwirken, was mir half, meine Fähigkeiten in der Projektkoordination weiterzuentwickeln. Dabei arbeiteten wir an innovativen Lösungen, um die Nutzererfahrung zu optimieren und die Marke in beiden Ländern zu stärken.',
        image: [{ src: maxenergyAT, alt: 'Screenshot von der MAXENERGY Website Österreich' }, { src: maxenergyDE, alt: 'Screenshot von der MAXENERGY Website Deutschland' }],
        links: [{
            src: 'https://www.maxenergy.de/',
            text: 'MAXENERGY Deutschland'
        }, {
            src: 'https://www.maxenergy.at/',
            text: 'MAXENERGY Österreich'
        }]
    }, {
        title: 'OpenGL Engine',
        content: 'Ein kleines OpenGL-Projekt, das verschiedene Assets auf dem Bildschirm rendert. Die Herausforderung bestand darin, von einem einfachen Dreieck über die Implementierung erster Texturen bis hin zur Darstellung mehrerer Texturen in einem einzigen Batch zu gelangen.',
        links: [{
            src: 'https://github.com/Collabo93/Engine',
            text: 'Source Code'
        }]
    }, {
        title: 'SortGroup',
        content: 'Ein kleines Nebenprojekt entwickelte sich mit über 110.000 Downloads zu einem meiner erfolgreichsten Vorhaben. Ich wollte die Programmiersprache LUA erlernen und testen, wie erfolgreich ein Projekt ohne eigene Promotion sein kann. Rückblickend lässt sich sagen, dass Empfehlungen eines der stärksten Marketing-Tools sind.',
        image: sortgroup,
        alt: 'Screenshot des Codes von SortGroup',
        link: 'https://legacy.curseforge.com/wow/addons/project-96442',
        links: [{
            src: 'https://github.com/Collabo93/SortGroup',
            text: 'Source Code'
        }, {
            src: 'https://legacy.curseforge.com/wow/addons/project-96442',
            text: 'Website'
        }]
    }, {
        title: 'Website Roulette',
        content: 'Die Hauptidee des Projekts war, einen Website-Scraper zu entwickeln, um eine Datenbank für die Website zu erstellen. Derzeit stehen über 25.000 Links zur Verfügung.',
        image: websiteRoulette,
        alt: 'Screenshot von der Website Roulette Website',
        links: [{
            src: 'https://github.com/Collabo93/Collabo93.github.io',
            text: 'Source Code'
        }, {
            src: 'https://collabo93.github.io/',
            text: 'Website'
        }]
    }, {
        title: 'Portfolio',
        content: 'Im Modul „Advanced Web Engineering“ hatte ich in meinem Studium die Gelegenheit, tiefgehende Kenntnisse in Vue.js und React zu erwerben. Um diese erlernten Fähigkeiten aufzufrischen, entschied ich mich, diese Portfolio-Website in React zu entwickeln.',
        image: luaLogo,
        alt: 'Lua Logo',links: [{
            src: 'https://github.com/Collabo93/portfolio',
            text: 'Source Code'
        }]
    }
]

export const experience = [
    {
        year: '2024',
        title: 'Webentwickler',
        subtitle: 'MAXENERGY Deutschland, Augsburg',
        content: 'In meiner Rolle als Webentwickler habe ich den kompletten Relaunch unserer Website in zwei Ländern erfolgreich umgesetzt. Dieses Projekt stellte nicht nur eine technische Herausforderung dar, sondern erforderte auch eine enge Zusammenarbeit mit verschiedenen Teams, um die Nutzererfahrung zu optimieren und die Markenpräsenz zu stärken. Durch die Anwendung innovativer Technologien und Ansätze konnte ich meine Fähigkeiten in der Webentwicklung weiter vertiefen und wertvolle Erfahrungen in der Projektkoordination sammeln.'
    },
    {
        year: '2021',
        title: 'Webdesigner',
        subtitle: 'MAXENERGY Deutschland, Augsburg',
        content: 'In meiner Rolle als Webdesigner habe ich erfolgreich Projekte geleitet, darunter die Neuentwicklung der Bestellstrecke, eines Kundenportals und einer REST-API. Obwohl die Jobbezeichnung "Webdesigner" nicht ganz meinen tatsächlichen Aufgaben entspricht, habe ich umfangreiche Erfahrungen in der Webentwicklung gesammelt. Diese Projekte haben mir ermöglicht, meine technischen Fähigkeiten auszubauen und Lösungen zu entwickeln, die sowohl benutzerfreundlich als auch leistungsstark sind.'
    },
    {
        year: '2021',
        title: 'Bachelor of Science (B.SC.)',
        subtitle: 'Internet und Online-Marketing, Ravensburg',
        content: 'Im Rahmen meines Studiums im Bereich Internet- und Online-Marketing habe ich gezielt Schwerpunkte in der Webentwicklung gesetzt. Durch praxisnahe Projekte und theoretische Grundlagen konnte ich ein umfassendes Verständnis für die Erstellung und Optimierung von Webanwendungen entwickeln. Dieses Wissen bildet die Grundlage für meine Fähigkeit, innovative Lösungen zu gestalten, die sowohl technisch als auch marktorientiert sind.'
    },
];

export const connect = {
    icon: socialEmail,
    alt: 'E-Mail Icon',
    string: 'blessing.timo@gmail.com'
}


export const social = [
    {
        icon: socialTelegram,
        link: 'https://t.me/blessing_timo',
        alt: 'Telegram Icon'
    }, {
        icon: socialGithub,
        link: 'https://github.com/Collabo93',
        alt: 'GitHub Icon'
    }, {
        icon: socialLinkedIn,
        link: 'https://de.linkedin.com/in/timo-blessing-1875ab261',
        alt: 'LinkedIn Icon'
    }
]

export const skills = [
    {
        token: 'Og',
        name: 'Open GL',
        type: 'others',
        text: 'OpenGL ist eine Grafik-API, die ich in einem Projekt verwendet habe, um verschiedene Assets zu rendern. Von der Darstellung eines Dreiecks bis zum Batching mehrerer Texturen hat mir dieses Projekt spannende Einblicke in die Herausforderungen der Grafikdarstellung gegeben.'
    }, {
        token: 'Me',
        name: 'Memes',
        type: 'hobbys',
        text: 'One does not simply make portfolio website without a meme'
    }, {
        token: 'N+',
        name: 'Notepad++',
        type: 'tools',
        text: 'Mein Lieblingstexteditor, den ich sehr häufig verwende.'
    }, {
        token: 'Lh',
        name: 'Lighthouse',
        type: 'tools',
        text: 'Lighthouse ist ein praktisches Tool zur Analyse von Webanwendungen. Um eine benutzerfreundliche, schnelle und barrierefreie Website zu entwickeln, ist es ein essentielles Tool für mich.'
    }, {
        token: 'Lu',
        name: 'Lua',
        type: 'others',
        text: 'Der kleine, aber mächtige Skripting-Allrounder. Perfekt für alle, die Flexibilität und Geschwindigkeit brauchen, ohne sich in komplizierten Syntax-Wirrwarr zu verlieren.'
    }, {
        token: 'Vj',
        name: 'Vue.js',
        type: 'frontend',
        text: 'Vue.js ist ein modernes JavaScript-Framework, das ich während meines Studiums kennenlernen durfte. Die einfache Integration und Flexibilität machen es zu einem tollen Werkzeug für meine Projekte.'
    }, {
        token: 'Re',
        name: 'React',
        type: 'frontend',
        text: 'Wie Vue.js habe ich mir auch React in meinem Studium angeeignet. Um meine Kenntnisse aufzufrischen, entschied ich mich, diese Portfolio-Website in React zu entwickeln.'
    }, {
        token: 'Ba',
        name: 'Batch Script',
        type: 'others',
        text: 'Batch-Skripte sind eine praktische Möglichkeit, Routineaufgaben in Windows zu automatisieren. Für einen von mir selbst entwickelten Build-Prozess ist es ein essentielles Tool.'
    }, {
        token: 'Bs',
        name: 'Bootstrap',
        type: 'frontend',
        text: 'Bootstrap verwende ich gerne, um kleine Projekte schnell umsetzen zu können.'
    }, {
        token: 'Fb',
        name: 'Firebase',
        type: 'backend',
        text: 'Firebase ist eine tolle Plattform, die ich für das Backend meiner Projekte benutze. Sie bietet Funktionen wie Echtzeit-Datenbanken und Authentifizierung, die die Entwicklung erleichtern und die benötigte Serverinfrastruktur abnehmen.'
    }, {
        token: 'Nj',
        name: 'Node.js',
        type: 'backend',
        text: 'Node.js ist eine leistungsstarke JavaScript-Laufzeitumgebung, die ich oft für das Backend einsetze. Sie ermöglicht mir, serverseitige Anwendungen schnell zu entwickeln.'
    }, {
        token: 'Wp',
        name: 'Wordpress',
        type: 'frontend',
        text: 'WordPress ist ein vielseitiges CMS, das ich gerne für schnelle Webprojekte nutze und bei denen ich wenig Zeit in die technische Umsetzung stecken möchte.'
    }, {
        token: 'Cv',
        name: 'Canvas',
        type: 'tools',
        text: 'Die Erstellung Website beginnt mit einem Mokup. Canvas nutze ich sehr gerne dafür.'
    }, {
        token: 'Bc',
        name: 'Bitcoin',
        type: 'hobbys',
        text: 'Eine Herzensangelegenheit von mir: Die digitale Währung, die die Finanzwelt auf den Kopf gestellt hat. Dezentral, sicher und voller Potenzial – für alle, die in die Zukunft des Geldes investieren wollen.'
    }, {
        token: 'Cf',
        name: 'CTF',
        type: 'hobbys',
        text: 'CTFs (Capture the Flag) sind spannende Wettbewerbe, die ich nutze, um meine Cybersecurity-Fähigkeiten zu verbessern. Sie bieten praktische Herausforderungen im Bereich Hacking und Sicherheit. Nicht nur kann durch die Teilnahme das Wissen erweitert werden, sondern auch die Kreativität gefordert werden.'
    }, {
        token: 'Sa',
        name: 'Schach',
        type: 'hobbys',
        text: 'Auch wenn ich keine hohe Elo habe, spiele ich sehr gerne Schach in meiner Freizeit.'
    }, {
        token: 'Wp',
        name: 'Webpack',
        type: 'frontend',
        text: 'Webpack ist ein praktisches Build-Tool, das ich oft für meine Webprojekte nutze. Das Bundling von JavaScript-Dateien und anderen Assets, gepaart mit der Flexibilität, macht Webpack zu einem mächtigen und von mir sehr häufig benutztem Tool.'
    }, {
        token: 'Wa',
        name: 'Wandern',
        type: 'hobbys',
        text: 'Wandern ist eine meiner liebsten Aktivitäten, um den Kopf freizubekommen und neue Energie zu tanken. Die Natur inspiriert mich und bietet einen schönen Ausgleich zur Bildschirmarbeit.'
    }, {
        token: 'Pp',
        name: 'PHP',
        type: 'backend',
        text: 'Die Sprache, die hinter den meisten Webseiten steckt. Flexibel, serverseitig und perfekt für dynamische Inhalte findet PHP häufig Verwendung in meinen Projekten.'
    }, {
        token: 'Jr',
        name: 'Jira',
        type: 'tools',
        text: 'Jira ist ein nützliches Tool für Projektmanagement und Teamkoordination. Es hilft mir, Aufgaben zu verfolgen und mit meinem Team effektiv zu kommunizieren.'
    }, {
        token: 'Pm',
        name: 'Postman',
        type: 'tools',
        text: 'Das unschlagbare Tool für mich. Testen, debuggen und optimieren von APIs – alles an einem Ort.'
    }, {
        token: 'Rt',
        name: 'REST',
        type: 'backend',
        text: 'Ich hatte sowohl in meinem Studium als auch in meinem Beruf oft die Gelegenheit, APIs zu verwenden und zu entwickeln. Das größte Projekt war, ein API zu entwickeln, das als Interpreter zwischen einem System von uns und HubSpot fungiert.'
    }, {
        token: 'Wb',
        name: 'Weiterbildung',
        type: 'hobbys',
        text: 'Weiterbildung ist mir wichtig, um mit neuen Technologien und Trends Schritt zu halten. Ich nutze Online-Kurse und Workshops, um meine Fähigkeiten auszubauen. Ganz getreu nach dem Motto: "What I cannot create, I do not understand."'
    }, {
        token: 'Jq',
        name: 'jQuery',
        type: 'frontend',
        text: 'Auch wenn jQuery in der heutigen Zeit nicht mehr wirklich benötigt wird, da JavaScript mittlerweile alles nativ mitbringt, ist es ein essentieller Teil von Webflow und somit ein häufiger Bestandteil meiner Arbeit.'
    }, {
        token: 'Wf',
        name: 'Webflow',
        type: 'frontend',
        text: 'Webflow ist ein Website-Builder, der häufig Verwendung in meinem täglichen Doing findet.'
    }, {
        token: 'Sc',
        name: 'Scrum',
        type: 'tools',
        text: 'Scrum ist ein agiles Framework, das ich für Teamarbeit und Projektmanagement einsetze. Es fördert die Zusammenarbeit und schnelle Anpassung durch regelmäßige Sprints.'
    }, {
        token: 'Hl',
        name: 'HTML',
        type: 'frontend',
        text: 'Die Basis für jede Website und somit ein wichtiger Bestandteil meiner Arbeit. Ich lege dabei sehr viel Fokus auf die Semantik, um die Barrierefreiheit zu gewährleisten.'
    }, {
        token: 'Cs',
        name: 'CSS',
        type: 'frontend',
        text: 'Der Grund, der mich zur Webentwicklung gebracht hat: Programmierung mit der Möglichkeit, Design darin zu vereinen. CSS – ein wichtiger Bestandteil meiner Arbeit und eine Möglichkeit, seiner Kreativität freien Lauf zu lassen.'
    },  {
        token: 'Vc',
        name: 'Visual Studio Code',
        type: 'tools',
        text: 'Visual Studio Code ist mein bevorzugter Code-Editor für nahezu alle meine Projekte.'
    }, {
        token: 'Gi',
        name: 'Git',
        type: 'tools',
        text: 'Ein essentielles Tool, um Versionskontrolle zu gewährleisten. Zusammen mit GitHub verwende ich Git so gut wie jeden Tag.'
    },{
        token: 'Js',
        name: 'JavaScript',
        type: 'frontend',
        text: 'Von allen Sprachen, die ich bereits verwendet habe, ist JavaScript meine bevorzugte und mit Abstand am häufigsten verwendete Sprache. Meine Arbeit wäre ohne sie nicht möglich.'
    },
];

