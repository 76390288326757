import { Container, Row, Col } from "react-bootstrap";
import Social from "../ui/Social";

export const Footer = () => {
    return (
        <footer id="footer">
            <Container>
                <Row>
                    <Col>
                        <Social className="contact__social" />
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col>
                        <div className="footer__quote">
                            <p>"Danke für's scrollen"</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}