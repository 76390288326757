import { Container, Col, Row } from "react-bootstrap"
import { useEffect, useState, useCallback } from "react";
import CodePanel from "../ui/CodePanel";
import Social from "../ui/Social";

export const Hero = () => {
    const [loopNr] = useState(0);
    const [isDeleting] = useState(false);
    const [text, setText] = useState('');
    const [delta] = useState(200);

    const tick = useCallback(() => {
        const toRotate = ['Timo Blessing'];
        let i = loopNr % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting 
            ? fullText.substring(0, text.length - 1) 
            : fullText.substring(0, text.length + 1);

        setText(updatedText);
    }, [loopNr, isDeleting, text]);

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta);

        return () => {
            clearInterval(ticker);
        };
    }, [tick, delta]);
    return (
        <section id="home" className="hero">
            <Container>
                <Row className="align-items-center">
                    <Col md={12} lg={7}>
                        <h1><span id="name" className="wrap">{text}</span></h1>
                        <p className="job">Webentwickler &<br />Webdesigner</p>
                        <Social />
                        <a id="kontakt" href='mailto:blessing.timo@gmail.com'>Kontakt</a>
                    </Col>
                    <Col md={12} lg={5}>
                        <CodePanel />
                    </Col>
                </Row>
                <Row>
                    <Col>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}