import { candidate } from '../../data';
import jsIcon from '../../assets/img/hero/js-icon.svg'

const CodePanel = () => {
    return (
        <div className="code-panel__container">
            <div className='code-panel__tab'>
                <img src={jsIcon} alt="JavaScript Icon" className='code-panel__tab__icon'/>
                tblessing.js
            </div>
        <div className="code-panel">
            <code>
                <div>
                    <div>
                        <p><span className="keyword">const</span> <span className="variable">developer</span> = <span className="operator">&#123;</span></p>
                    </div>

                    <div className="padding-tab">
                        <p><span className="property">name: </span><span className="string">'{candidate.name}'</span>,</p>
                    </div>

                    <div className="padding-tab">
                        <p><span className="property">skills: </span><span className="special-keyword">&#123;</span></p>
                        <p className="padding-tab"><span className="property">webTechnologies: </span><span className="keyword">&#91;</span>{candidate.skills.map((skill, index) => (
                            <span key={index}>
                                <span className="string">'{skill}'</span>{index === candidate.skills.length - 1 ? '' : ', '}
                            </span>
                        ))}<span className="keyword">&#93;</span>,</p>
                        <p className="padding-tab"><span className="property">otherTechnologies: </span><span className="keyword">&#91;</span>{candidate.otherTechnologies.map((skill, index) => (
                            <span key={index}>
                                <span className="string">'{skill}'</span>{index === candidate.otherTechnologies.length - 1 ? '' : ', '}
                            </span>
                        ))}<span className="keyword">&#93;</span></p>
                        <p><span className="special-keyword">&#125;</span>,</p>
                    </div>

                    <div className="padding-tab">
                        <p><span className="property">personalityTraits: </span><span className="special-keyword">&#123; </span></p>
                        {Object.entries(candidate.personalityTraits).map(([trait, value], index) => (
                            <div key={trait} className="padding-tab">
                                <p>
                                    <span className="property">{trait}: <span className="keyword">{value ? 'true' : 'false'}</span></span>{index === Object.entries(candidate.personalityTraits).length - 1 ? '' : ','}
                                </p>
                            </div>
                        ))}
                        <p><span className="special-keyword">&#125;</span>,</p>
                    </div>

                    <div className="padding-tab">
                        <p><span className="property">education: </span><span className="special-keyword">&#123; </span></p>
                        {Object.entries(candidate.education).map(([trait, value], index) => (
                            <div key={trait} className="padding-tab">
                                <p>
                                    <span className="property">{trait}: <span className="string">'{value}'</span></span>{index === Object.entries(candidate.education).length - 1 ? '' : ','}
                                </p>
                            </div>
                        ))}
                        <p><span className="special-keyword">&#125;</span>,</p>
                    </div>

                    <div className="padding-tab">
                        <p><span className="operator">funFact</span><span className="property">: </span><span className="special-keyword">()</span><span className="keyword"> =&gt;</span></p>
                        <p className="padding-tab"><span className="string">'{candidate.funFact}'</span></p>
                    </div>

                    <div>
                        <p><span className="operator">&#125;</span></p>
                    </div>
                </div>
            </code>
        </div>
        </div>
    );
};

export default CodePanel;
