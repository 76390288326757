import { skills } from "../../data";

const SkillsCard = () => {
    return (
        <div className="grid-container">
            <div className="periodic-table">
                <div className="empty-spacer-1"></div>
                <div className="empty-spacer-2"></div>

                {skills.map((element, index) => (
                    <div key={index} className={`periodic-element ${element.type}`}>
                        <div className="peridoic-number">{index+1}</div>
                        <div className="periodic-element-background" />
                        <div className="periodic-element-inner" data-description={element.text}>
                            <div className="title">{element.token}</div>
                            <div className="description">{element.name}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="legend-table">
                <div className="legend-table__wrapper">
                    <span className="legend-table__marker frontend"></span>
                    <span className="legend-table__text">Frontend</span>

                    <span className="legend-table__marker backend"></span>
                    <span className="legend-table__text">Backend</span>

                    <span className="legend-table__marker tools"></span>
                    <span className="legend-table__text">Tools</span>

                    <span className="legend-table__marker hobbys"></span>
                    <span className="legend-table__text">Hobbys</span>

                    <span className="legend-table__marker others"></span>
                    <span className="legend-table__text">Anderes</span>

                    <br />

                    <div className="legend-table__direction">
                        <span>&#8594; &ensp; Von links nach rechts: Wichtigkeit</span>
                    </div>

                    <div className="legend-table__direction">
                        <span>&#8595; &ensp; Von oben nach unten: Aufgewendete Zeit</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkillsCard;