const ButtonComponent = ({ link, hasBackground, text }) => {
    const baseClass = 'button';
    const backgroundClass = hasBackground ? 'button--full' : 'button--half';

    return (
        <a href={link} target="_blank" rel="noreferrer" className={`${baseClass} ${backgroundClass}`}>
            {text}
        </a>
    );
};

export default ButtonComponent;
